import React from 'react'
import Customer_header from './Customer_header'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Footer from './Footer'
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../config"
import Moment from 'react-moment';
import Header_nav from './Header_nav';
import download from 'downloadjs'

const Developer_account = () => {
    let navigate = useNavigate()
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
     }
  
    const[state,setState]= React.useState({shipment_list:"",customer_id:"",isLoading:true})
  
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 5000
  })

    
    React.useEffect(() => {
       shipment_list()
    }, [])

    const shipment_list=(status)=>{
        let dataToSend = {customer_id : userData.customer_id
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/user/user_secret_key';
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            setState({shipment_list:res.data.output,customer_id:res.data.customer_id,isLoading:false})
         
          })
          .catch((error) => {
             
              // console.log(error);
          });    

    }
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            // console.log('Text copied to clipboard');
            Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'success',
                title: "Text copied to clipboard",
                color:"white"
              });
             
            // You can also show a success message or perform any other desired action here
          })
          .catch((error) => {
            // console.error('Error copying text to clipboard:', error);
            // You can show an error message or handle the error in any appropriate way here
          });
      };
      const handleCopy1 = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            // console.log('Text copied to clipboard');
            Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'success',
                title: "Text copied to clipboard",
                color:"white"
              });
             
            // You can also show a success message or perform any other desired action here
          })
          .catch((error) => {
            // console.error('Error copying text to clipboard:', error);
            // You can show an error message or handle the error in any appropriate way here
          });
      };
    
  return (
    <div>
      <Header_nav/>
      <div class="sub_header_inner">
</div>
      <section class="py-4 bg-light">
        
        <div class="container width_custom bg-white mt-3  p-3">
            <h3>Develoepr Settings</h3>
            <p>Please use below key/token to access and authrize the apis.</p>
            <p>Developer API: <a href="/docs/Api_documentation.html">http://www.shiport.in/developer</a></p>
            <h4 class="pt-3">Key:</h4>
            <table class="table ">
                <thead>
                  <tr>
                    <th>Particular</th>
                    <th>Value</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Secret Key</td>
                    <td>{state.shipment_list}</td>
                    <td><a onClick={()=>handleCopy(state.shipment_list)} class="btn btn-main">Copy</a> </td>
                  </tr>
                </tbody>
              </table>

              <h4 class="pt-3">Customer ID:</h4>
            <table class="table ">
                <thead>
                  <tr>
                    <th>Particular</th>
                    <th>Value</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Customer Id</td>
                    <td>{state.customer_id}</td>
                    <td><a onClick={()=>handleCopy1(state.customer_id)} class="btn btn-main">Copy</a></td>
                  </tr>
                </tbody>
              </table>
        </div>
        
       
</section>        
        
    </div>
  )
}

export default Developer_account
