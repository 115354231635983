import React from 'react'
import Left_menu from './Left_menu'

import Billing_nav from './Billing_nav'
import {  useNavigate } from 'react-router-dom'

import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../../config"
import Header from './Header';
import { useNetworkState } from 'react-use';


const Dashboard = () => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const[balancestate,setbalanceState]= React.useState({total_balance:[]})
    const[companystate,setcompanyState]= React.useState({})

 
  const [state, setstate] = React.useState({ today_shipments:0 , yesterday_shipments:0  , today_orders:0 , yesterday_orders:0 , total_shipments:0 , pickup_shipments:0 , transit_shipments:0 , delivered_shipments:0 , today_revenue:0 , yesterday_revenue:0});
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
  let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
  React.useEffect(()=>{
   
    get_wallet_balance()
    get_dashboard_data()
  },[])

    const get_wallet_balance=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/user/get_customer_wallet';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
           setbalanceState({total_balance:res.data.find_customer_wallet})
           setcompanyState(res.data.company_info)
        }
       else{
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"rgb(231, 91, 75)",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
       } })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"rgb(231, 91, 75)",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }

       const get_dashboard_data=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/home/dashboard_api';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
        //    setbalanceState({total_balance:res.data.find_customer_wallet})
        setstate({...state , today_shipments:res.data.today_shipments , yesterday_shipments:res.data.yesterday_shipments , today_orders:res.data.today_orders , yesterday_orders:res.data.yesterday_orders , total_shipments:res.data.total_shipments , pickup_shipments:res.data.pickup_shipments ,transit_shipments:res.data.transit_shipments , delivered_shipments:res.data.delivered_shipments , today_revenue: res.data.today_revenue , yesterday_revenue:res.data.yesterday_revenue })
        }
       else{
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"rgb(231, 91, 75)",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
       } })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"rgb(231, 91, 75)",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }

       const recharge_page =(sub)=>{
          // console.log("sub == " , sub[0].aadhar_kyc)
          if(sub[0].aadhar_kyc!=undefined && sub[0].aadhar_kyc==true){
         navigate('/customer/wallet_recharge')
          }
          else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'Unsuccess',
                title: "Need to do Kyc first !",
                color:"white"
              });
          }
       }
    return (
        <div >
            <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
            <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>
          {balancestate.total_balance.map((sub)=>(
            sub.aadhar_kyc==false?
          <section>
            <div className='mt-2 mb-3 alert alert-danger'>Your KYC is not verified . You can book {companystate.limit_shipments} shipments till KYC not verified</div>
          </section>
          :""
          ))}
                <section class="">
                  
                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class="nav-link active" href="#">Overview</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="/customer/orders">Orders</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="/customer/shipments">Shipments</a>
                            </li>
                            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <li className='nav-item'>
                            <a class="nav-link" >Credit Limit :  {balancestate.total_balance.map((sub)=>(
                      sub.credit_limit? parseFloat(sub.credit_limit).toFixed(2):0))}</a>
                            </li>
                            */}
                        </ul>
                       
               
                </section>

                <section class="mb-3 pt-3">
                  
                        <div class="header-body">
                            <div class="row">
                                <div class="col-xl-3 col-lg-6">
                                    <div class="card card-stats mb-4 mb-xl-0 bg-primary text-white">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <h5 class="card-title text-uppercase mb-0">Wallet </h5>
                                                    <span class="h2 font-weight-bold mb-0">&#8377; {balancestate.total_balance.map((sub)=>(
                       parseFloat(sub.wallet_balance).toFixed(2)))}</span>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="icon icon-shape bg-light text-primary rounded-circle shadow">
                                                        <i class="material-icons-outlined">account_balance_wallet</i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pt-3 ">
                                                <a  onClick={(e)=>recharge_page(balancestate.total_balance.map((sub)=>(
                    sub)))} class="btn btn-outline-light btn-sm">Recharge</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6">
                                    <div class="card card-stats mb-4 mb-xl-0">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <h5 class="card-title text-uppercase text-muted mb-0">Today's Order</h5>
                                                    <span class="h2 font-weight-bold mb-0">{state.today_orders}</span>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                                                        <i class="material-icons-outlined">local_mall</i>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="mt-2 mb-0 text-muted text-sm">
                                                <span class="text-primary mr-2">{state.yesterday_orders}</span><br />
                                                <span class="text-nowrap">Yesterday's Order</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6">
                                    <div class="card card-stats mb-4 mb-xl-0">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <h5 class="card-title text-uppercase text-muted mb-0">Today's Revenue</h5>
                                                    <span class="h2 font-weight-bold mb-0"><small></small>&#8377;{state.today_revenue}</span>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="icon icon-shape bg-success text-white rounded-circle shadow">
                                                        <i class="material-icons-outlined">savings</i>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="mt-2 mb-0 text-muted text-sm">
                                                <span class="text-primary mr-2">&#8377;{state.yesterday_revenue}</span><br />
                                                <span class="text-nowrap">Yesterday's Revenue</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6">
                                    <div class="card card-stats mb-4 mb-xl-0">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <h5 class="card-title text-uppercase text-muted mb-0">Today's Shipments</h5>
                                                    <span class="h2 font-weight-bold mb-0">{state.today_shipments}</span>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="icon icon-shape bg-primary text-white rounded-circle shadow">
                                                        <i class="material-icons-outlined">view_in_ar</i>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="mt-2 mb-0 text-muted text-sm">
                                                <span class="text-primary mr-2">{state.yesterday_shipments}</span><br />
                                                <span class="text-nowrap">Yesterday's Shipments</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                  
                </section>

                <section class="mb-3">
                  
                        <div class="card border-0">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <div class="">
                                        <h6>Shipments Details</h6>
                                    </div>
                                    <div class="">
                                        <small>Last 30 days</small>
                                    </div>
                                </div>
                                <div class="row states py-3">
                                    <div class="col-12 col-md-2 mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>{state.total_shipments}</h5>
                                            <small>Total Shipments</small>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2 mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>{state.pickup_shipments}</h5>
                                            <small>Pickup Pending</small>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2 mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>{state.transit_shipments}</h5>
                                            <small>In-Transit</small>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2 mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>{state.delivered_shipments}</h5>
                                            <small>Delivered</small>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2 mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>----</h5>
                                            <small>NDR Pending</small>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2 mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>----</h5>
                                            <small>RTO</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                </section>

                <section class="mb-3">
                  
                        <div class="card border-0">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <div class="">
                                        <h6>COD Details</h6>
                                    </div>
                                    <div class="">
                                        <small>Last 30 days</small>
                                    </div>
                                </div>
                                <div class="row states py-3">
                                    <div class="col-12 col-md mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>--</h5>
                                            <small>Total COD (Last 30 Days)</small>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>--</h5>
                                            <small>COD Available</small>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>--</h5>
                                            <small>COD Pending (Greater than 8 days)</small>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>--</h5>
                                            <small>Last COD Remitted</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                </section>

                {/* <section class="mb-3 pb-4">
                    <div class="container">
                        <div class="card border-0">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <div class="">
                                        <h6>NDR Details</h6>
                                    </div>
                                    <div class="">
                                        <small>Last 30 days</small>
                                    </div>
                                </div>
                                <div class="row states py-3">
                                    <div class="col-12 col-md mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>0</h5>
                                            <small>Total NDR (Last 30 Days)</small>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>0</h5>
                                            <small>Your Reattempt Request</small>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>0</h5>
                                            <small>Buyer Reattempt Request</small>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md mb-3">
                                        <div class="stbox text-center py-4 border rounded bg-light">
                                            <h5>0</h5>
                                            <small>NDR Delivered</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

           

            </div>
            {/* <?php include 'footer.php';?> */}
        </div>
    )
}

export default Dashboard
