import React, {  useRef } from 'react'
import Header from './Header'
import Left_menu from './Left_menu'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Barcode from 'react-barcode';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import html2canvas from 'html2canvas';
import BarcodeComponent from './BacodeComponent'
import { renderToString } from 'react-dom/server';
import createRoot from 'react-dom';
import { useNetworkState } from 'react-use';
import { BarcodeIcon, CornerUpLeft, FileDown, Truck } from 'lucide-react';

const Rto_shipments = () => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const barcodeRef = useRef(null);
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const[loaderpickup , setloaderpickup] = React.useState(false)
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const[state,setState]= React.useState({shipment_list:[],isLoading:true})
    const[labelstate,setlabel]= React.useState({shipment_list:[],isLoading:true})
    const[searchstate,setsearchstate]= React.useState({search_shipment:""})

    const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
    const [shipSelect, setShipSelect] = React.useState({ shipment_id: "",cancel_reason:"" , total_amount:0 });
    const [modalState, setModalState] = React.useState({ show: false });
    const [modalpickupState, setModalpickupState] = React.useState({ show: false });
    const[isLoading , setisLoading] =React.useState(true)
    const[pickupstate , setpickupstate] = React.useState({  warehouse_name:""});
    // console.log("pickupstate ==== " , pickupstate)
    const [selectedShipments, setSelectedShipments] = React.useState([]);
    // console.log("selectedShipments === " ,selectedShipments)
    const [selectCount ,setSelectCount] = React.useState({select_count:0 })
    const[addressState, setaddressState] = React.useState([]);
   
    const [otherStates,setOtherState] = React.useState({dstatus:"",activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
    // console.log("otherState ==== " , otherStates)
    const[isloading , setisloading] = React.useState(true)

    const [selectedDateTime, setSelectedDateTime] = React.useState(null);
    const [selectedTime, setSelectedTime] = React.useState(null);
    const[cancelshipment , setcancelshipment] = React.useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 5000
  })

    // console.log("shipmentState",shipmentstate)
    React.useEffect(() => {
  
        shipment_list(null,0,true)
        dimension_detail()
    address_book_detail()
    
      }, [otherStates.rowsPerPage])
    

    const shipment_list=(status,index=0,onLoad)=>{
      if(isOnline.online==true){
        let dataToSend = {customer_id : userData.customer_id ,find:searchstate.search_shipment,  status:status , limit:otherStates.rowsPerPage,indexValue:index
    };
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/rto_shipments';
     
     // console.log("headers =========> ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
      // // console.log("headers ====== " , config.headers)
          .then((res) => {
            // console.log("responseJson => ", res);
            if( onLoad) {
              setOtherState({...otherStates,total_count:res.data.count , page:index})         

  }
            setState({shipment_list:res.data.output,isLoading:false})
            setisLoading(false)
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Network Connection Error",
            
          })
        }

    }

    const label_shipments=()=>{
      if(isOnline.online==true){
      let dataToSend = {customer_id : userData.customer_id,shipment_id:selectedShipments
  };
    let url = config.apiUrl + '/shipment/download_label_shipments';
     axios.post(url, dataToSend, { headers: config.headers })
        .then((res) => {
          console.log("responseJson => ", res);
          setlabel({shipment_list:res.data.output,total : res.data.total , company_info:res.data.company_info})
          setisloading(false)
         
          
        })
        .catch((error) => {
        
        });    
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Network Connection Error",
          
        })
      }

  }
 
  React.useEffect(() => {
    // This effect will run every time labelstate is updated
    if (labelstate.shipment_list.length > 0) {
      onDownloadPDF();
    }
  }, [labelstate]); 
 
  const onDownloadPDF = async() => {
    const content = [];
    for (const sub of labelstate.shipment_list) {
        try {
          const pdfContent = htmlToPdfmake(`
          <table width="100%" border="0" style="border-collapse: collapse; border: 2px solid #000; font-size: 11px; line-height:1;">
          <tr class="" style="margin:0; padding:0px">
            <td colSpan='2' style='margin:0; padding:0px; font-weight:bold;'>
              Ship To:
            </td>
            <td align="right" style='margin:0; padding:0px;'>
            ${moment(new Date(sub.created * 1000)).format("MMM DD YYYY")}
            </td>
          </tr>
          <tr class="">
            <td colspan="3">
              <strong style="font-size:16px;">${sub.receiver[0].name}</strong></br>
               ${(sub.receiver[0].address)}</br>
                ${sub.receiver[0].city},${sub.receiver[0].state} ${sub.receiver[0].pincode} &nbsp;
                Mob: ${sub.receiver[0].mobile}
              
            </td>
          </tr>
          <tr class="">
            <td class="" style="width: 60%;">
             <strong style='font-size:10px;'>Shipment Mode</strong>:</br>
              ${sub.product_type_name?sub.product_type_name:"------"}</br>
              <strong style='font-size:10px;'>Payment Type</strong>:</br>
              ${sub.payment_mode}
            </td>
            <td colspan="2" style="width: 40%;">
            <strong style='font-size:16px;'>${sub.payment_mode=="cod"?`COD Amount: INR ${sub.cod_amount}`:"DO NOT COLLECT CASH"}</strong>
            </td>
          </tr>
          <tr style="border-bottom:0;">
            <td colspan="2" style="border-bottom:0; text-align:right; border-right:0;">
            <strong style='font-size:14px;'>${sub.carrier_name}</strong> 
            </td>
            <td colspan="0" style="border-bottom:0;  border-left:0;">
            <strong style='font-size:10px;'>ROUTE ----</strong> 
            </td> 
           </tr>
           <tr style="border-top:0;">
           <td colspan="3" style="margin: 0; padding: 0; line-height: 0; font-size: 0; border-top:0;">
           <img src="${sub.barcode_url_carrier}" style="height: 55px; width: 250px; margin-left:8px; display: block; padding: 0;">
           </img>
           </td>
          </tr>
          <tr class="border-bottom">
          <td>
          Product
      </td>
      <td>
          Qty
      </td>
      <td>
          Price
      </td>
  </tr> 
  ${sub.items.map((item)=>(`
    <tr class="border-bottom">
    <td>
    ${item.item_name}
    </td>
    <td colspan="">
    ${item.item_quantity}
    </td>
    <td colspan="">
        ${item.item_value}
    </td>
</tr>`
  ))}
  <tr class="border-bottom">
    <td>
      Total
    </td>
    <td colspan="">
      
    </td>
    <td colspan="">
    ${sub.totalValue}
    </td>
  </tr>
  <tr>
  <td colspan="3" class="" style="margin: 0; padding: 0; line-height: 0; font-size: 0;">
  <img src="${sub.barcode_url}" style="height: 55px; width: 250px; margin-left:8px; display: block; padding: 0;">
</td>
  </tr>
  <tr style="margin: 0px;">
    <td colspan="3" class="" style="margin: 0px;">
    <strong style='font-weight:bold;'> Return Address</strong>: ${(sub.return[0].address)}, ${sub.return[0].city}-${sub.return[0].pincode}, ${sub.return[0].state}, ${sub.return[0].country}
    </td>
  </tr>
</table>

           `);
  
  
          content.push(pdfContent);
          content.push({ text: '', pageBreak: 'after' });
          setlabel({shipment_list:[]})
        } catch (error) {
          console.error(`Error converting HTML to pdfmake format for shipment :`, error);
        }
    };
    content.pop();
   const pdfDoc = {
      content,
      pageSize: { width: 4 * 72, height: 6 * 72 },
      pageOrientation: 'portrait',
    };
    pdfMake.createPdf(pdfDoc).download('labels.pdf');
  };
    

    const dimension_detail = () =>{
      let full_api = config.apiUrl + `/user/get_company_info_api`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
        // console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }
    const handlePageChange = (event,newPage) =>{
      setOtherState({...otherStates,page:newPage})
      // console.log("newPage ", newPage);
      // getCountryList(newPage);
      shipment_list(shipmentstate.shipment_status,newPage,true)
  }
  const handleChangeRowsPerPage = (event , newPage) => {
      // console.log("event ", event.target.value);
      setOtherState({ ...otherStates, rowsPerPage: event.target.value })
       
  }
   

    const cancelShipment = () =>{
      setcancelshipment(true)
      if(isOnline.online==true){
      if(shipSelect.cancel_reason != ""){
      let full_api = config.apiUrl + `/shipment/shipment_cancel`;
      let sendData = {customer_id:userData.customer_id,shipment_id:shipSelect.shipment_id,cancel_reason:shipSelect.cancel_reason , total_amount:shipSelect.total_amount};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        Toast.fire({
          background: "#10b93b",
          type: 'success',
          title: res.data.message,
          color: "white"
      });
        if(res.data.status == "success"){
          setcancelshipment(false)
                setShipSelect({cancel_reason:"",shipment_id:""});
                setModalState({ show: false })
                shipment_list()
        }
        else{
          setcancelshipment(false)
        }
        // console.log("rnmmmmm", res);
      }).catch((e) => {
        setcancelshipment(false)
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }else {
      Toast.fire({
        background: "#10b93b",
        type: 'success',
        title: "Enter Reason to Cancel.",
        color: "white"
    });
    }
  }else{
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: "Network Connection Error",
      
    })
  }
    }

    const inputHandleChange = (e) => {
      const value = e.target.value;
      const text = e.target.name;
      setShipSelect({ ...shipSelect, [text]: value })
    }
    
   const shipping_label =(sub)=>{
    // console.log("sub ==== " , sub)
    if(sub.carrier_id=="1656377059"){
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://dtdcapi.shipsy.io/api/customer/integration/consignment/shippinglabel/stream?reference_number='+sub.carrier_tracking_number,
      responseType: 'blob',
      headers: { 
        'Content-Type': 'application/pdf', 
        'api-key': 'e96fb203eef06314303c1aa7ef8c0c'
      }
    };
    
    axios.request(config)
    .then((response) => {
      // console.log((response.data));
      download(response.data, 'dtdc.pdf')
      
    })
    .catch((error) => {
      // console.log(error);
    });
  
  }
  else{
    let data = new FormData();
data.append('username', 'SHIPORTTECHNOLOGIES-EXSPLUS915066');
data.append('password', '6e3P9Xwy19');
data.append('awbs', sub.carrier_tracking_number);

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://shipment.ecomexpress.in/services/expp/shipping_label',
  
  data : data
};

axios.request(config)
.then((response) => {
  // console.log("ecom ===== ",JSON.stringify(response.data));
  // download(response.data, 'ecom.pdf')
})
.catch((error) => {
  // console.log(error);
});

  }
   }

   
  //  const getNext5Dates = () => {
  //   const today = new Date();
  //   const nextDates = [];

  //   for (let i = 0; i < 5; i++) {
  //     const nextDate = new Date(today);
  //     nextDate.setDate(today.getDate() + i);
  //     nextDate.setHours(today.getHours());
  //     nextDate.setMinutes(today.getMinutes());
  //     nextDates.push(formatDate(nextDate));
  //   }

  //   return nextDates;
  // };
  const getNext5Dates = () => {
    const today = new Date();
    const now = new Date(); // Get the current time
  
    const nextDates = [];
  
    // Check if the current time is before 1:00 PM
    if (now.getHours() < 14) {
      // Show today's date
      nextDates.push(formatDate(today));
    }
  
    // Calculate and show the dates for the next 4 days
    for (let i = 1; i < 5; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      nextDate.setHours(today.getHours());
      nextDate.setMinutes(today.getMinutes());
      nextDates.push(formatDate(nextDate));
    }
  
    return nextDates;
  };
  const formatDate = (date) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric'  };
    return date.toLocaleDateString(undefined, options);
  };
  const nextDates = getNext5Dates();
  const handleDateSelect = (date) => {
    setSelectedDateTime(date);
    // You can also close the modal or perform other actions here
  };
   
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedShipments(state.shipment_list.map((shipment) => shipment.shipment_id));
      setSelectCount({select_count : state.shipment_list.length})
    } else {
      setSelectedShipments([]);
      setSelectCount({select_count : 0})

    }
  };
  

  const address_book_detail = ()=>{
    let url = config.apiUrl + '/country/address_book';
    let sendData = {  customer_id: userData.customer_id };
    // console.log("bb", sendData);
    axios.post(url,sendData ,{headers:config.headers}).then((res)=>{
        if(res.data.status==true){
            // console.log("sasdfdsa")
            setaddressState(res.data.output)
            // setState([])
            // setCityList([])
            // setPincodeList([])
            // setaddState({...addstate, full_name:"" ,  state_name:"" , city_name:"" , pincode:"" , address:"" , email:"" , mobile:"" , warehouse_name:"", err:{} })
            // setOptions([])

             
        }

    }).catch(()=>{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'unsuccess',
            title: "Something Went Wrong",
            color:"white"
          });
   
       
    })
   }

   const inputHandleChangemodal=(e)=>{
    setpickupstate({...pickupstate , warehouse_name:e.target.value})
   }
   const pickup_request = ()=>{
   if(isOnline.online==true){
    let url = config.apiUrl + '/shipment/create_pickup_request_api';
    if(pickupstate.warehouse_name!=""){
      if( selectedDateTime!=null ){
      if(selectedTime!=null){
      setloaderpickup(true)
    let sendData = {  customer_id: userData.customer_id , warehouse_name : pickupstate.warehouse_name , date:selectedDateTime , time:selectedTime};
    // console.log("pickup_request === ", sendData);
    axios.post(url,sendData ,{headers:config.headers}).then((res)=>{
        if(res.data.status==true){
          setModalpickupState({ show: false })
          setloaderpickup(false)
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#206bc4",
            type: 'unsuccess',
            title: res.data.message,
            color:"white"
          });
          shipment_list()
            // // console.log("sasdfdsa")
          
        }
        else{
          setModalpickupState({ show: false })
          setloaderpickup(false)
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#206bc4",
            type: 'unsuccess',
            title: res.data.message,
            color:"white"
          });
        }

    }).catch(()=>{
      setModalpickupState({ show: false })
      setloaderpickup(false)
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#206bc4",
            type: 'unsuccess',
            title: "Something Went Wrong",
            color:"white"
          });
   
       
    })
      }else{
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        
        Toast.fire({
          background:"#206bc4",
          type: 'unsuccess',
          title: "Please Select Time",
          color:"white"
        });
      }
    }else{

    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
    })
    
    Toast.fire({
      background:"#206bc4",
      type: 'unsuccess',
      title: "Please Select Date",
      color:"white"
    });
    }
  }else{
  
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
    })
    
    Toast.fire({
      background:"#206bc4",
      type: 'unsuccess',
      title: "Please Select All",
      color:"white"
    });
  }
}else{
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: "Network Connection Error",
    
  })
}
   }
   const selectTime=(e)=>{
    setSelectedTime(e.target.value)
   }

   const handlesearchShipment=(e)=>{
    setsearchstate({search_shipment:e.target.value})
   }

   
   
  return (
//     <div>
       
//     <Left_menu value={3}/>

//     <section class="home ">
        
// <Header/>
// <Modal show={modalState.show} onHide={() => setModalState({ show: false })}>
// <Modal.Header closeButton>
//                     <Modal.Title>Do you really want to Cancel ?</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body >
//                 <div className="row">
//                       <div className="col-2 mb-3 mt-5" style={{marginLeft:"25px"}}>
//                       <i class="fa fa-envelope " style={{fontSize:"20px",marginTop:"10px"}} aria-hidden="true"></i>
//                       </div>
//                         <div className="col-md-10 mb-3 mt-5 " style={{marginLeft:"-50px"}}>
//                               <input className="form-control" id="first_name" type="text" placeholder="Enter Reason" name="cancel_reason" onChange={(e) => inputHandleChange(e)} required   style={{ background: "transparent", border: "none", borderBottom: "1px solid #000000",  outline:"none", boxShadow:"none"}}/>
//                          </div>
                         
                        
//                        <div>
                       
//                        {cancelshipment==false?
//                     <button className="btn mt-3" style={{color: "#fff", backgroundColor: "#D65142", display:"inline-block",textAlign:"center",verticalAlign:"middle",position:"relative",boxSizing:"border-box",marginLeft:"190px",padding: "0.84rem 2.14rem",
//     fontSize: "18px",fontWeight:"bold"}} onClick={() =>
//       cancelShipment()
//                         }>Yes</button>
//                         :""}
//                     </div>
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer>
               
//                   <Button variant="secondary" onClick={() => setModalState({ show: false })}>
//                         Close
//                     </Button>
                              
//                 </Modal.Footer>
//             </Modal>


//             <Modal show={modalpickupState.show} onHide={() => setModalpickupState({ show: false })} size='lg'>
// <Modal.Header closeButton>
//                     <Modal.Title> <h4 class="modal-title">Schedule Your Pickup</h4></Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body >
              
//       <div class="modal-body">
//         <div class="row booking_info_bx">
          
//             <div class="col-12 col-md-12 mb-3">
//               <div class="card bg-light border-0 shadow-sm">
//                 <div class="card-body">
//                   <div class="d-flex">
//                       <div class="p-2 text-primary">
//                         <i class="material-icons-outlined">local_shipping</i>
//                       </div>
//                       <div class="ps-4 ">
//                         <h6>Pickup Address</h6>
//                         <select class="form-select" name="warehouse_name" onChange={(e)=>inputHandleChangemodal(e)}>
//                           <option>Select</option>
//                           {addressState.map((sub)=>{
//                             return(
//                               <option value={sub.address_id}>{sub.warehouse_id}</option>
//                             )
                        
//                             })}
                        
//                         </select>
//                       </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="col-12 col-md-12 mb-3">
//               <div class="card bg-light border-0 shadow-sm">
//                 <div class="card-body">
//                   <div class="d-flex">
//                       <div class="p-2 text-primary">
//                         <i class="material-icons-outlined">today</i>
//                       </div>
//                       <div class="ps-4 shcelu_pil">
//                         <h6>Schedule Your Pickup</h6>
//                         <small>Please select a suitable date and time for the pickup.</small>
//                         <div class="mt-3">
//                         {nextDates.map((date, index) => (
//                           <>
//                            <a   class="me-2 btn-time mb-2 text-decoration-none" key={index} onClick={() => handleDateSelect(date)}>{(date)}
//                            <select name="time" onChange={(e)=>selectTime(e)}>
//                             <option value="9:00">9:00</option>
//                             <option value="10:00">10:00</option>
//                             <option value="11:00">11:00</option>
//                             <option value="12:00">12:00</option>
//                             <option value="13:00">13:00</option>
//                             <option value="14:00">14:00</option>
//                             <option value="15:00">15:00</option>
//                             <option value="16:00">16:00</option>
//                             <option value="17:00">17:00</option>
//                             <option value="18:00">18:00</option>
//                           </select>
//                           </a>
                         
                         
//             </>
//           ))}
            
                        
                     
                    
//                         {selectedDateTime && <p>Selected Date: {(selectedDateTime)}</p>}      
                       
//                         </div>
//                       </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//         </div>
//       </div>
//       <div class="modal-footer text-start">
//       {loaderpickup==true? <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />:  <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={()=>pickup_request()}>Schedule Pickup</button>}
//         <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal" onClick={()=>setModalpickupState({show:false})}>I'll Do this later</button>
//       </div>
    
  
//                 </Modal.Body>
               
//             </Modal>

//         <section class="mb-3 pt-4">
//           <div class="container">
//             <div class="row mb-3">
//               <div class="col-12 col-md-3">
//                 <div class="d-flex">
//                     <div class="">
//                         <h3>Shipments</h3>
//                     </div>
//                     <div class="ps-2 ">
//                         <select class="form-select form-select-sm">
//                           <option>All</option>
//                           <option>Domestic</option>
//                           <option>International</option>
//                         </select>
//                     </div>
//                 </div>
//               </div>
//               <div class="col-12 col-md-6">
//                 <div class="input-group input-group-sm mb-3">
//                     <span class="input-group-text"><i class="material-icons-outlined">search</i></span>
//                     <input type="text" class="form-control" name="search_shipment" placeholder="Enter Tracking Number" onChange={(e)=>handlesearchShipment(e)}/>
//                     <button class="btn btn-outline-primary" onClick={(e)=>shipment_list(null,0,true)}>Search</button>
//                   </div>
//               </div>
//               <div class="col-12 col-md-3 text-end">
//               <a class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#schedule_pikup" onClick={()=>openPickupModal()}><i class="fa fa-truck" aria-hidden="true"></i> Schedule Pickup</a>
//               </div>
              
            
//             </div> 
//             {selectCount.select_count==0?
//             <div>
//             <ul class="nav nav-pills">
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == null) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(null)}>All</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 1) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(1)}>Pickup Awaited</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 9) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(9)}>Pickup Scheduled</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 2) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(2)}>Picked Up</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 3) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(3)}>In Transit</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 4) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(4)}>Out For Delivery</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 5) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(5)}>Delivered</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 6) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer",}} onClick={() => shipmentFilter(6)}>Cancelled</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 7) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer",}} onClick={() => shipmentFilter(7)}>Failed</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 8) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer",}} onClick={() => shipmentFilter(8)}>NDR</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 10) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer",}} onClick={() => shipmentFilter(10)}>Not Picked</a>
//                         </li>
//                     </ul>
//             </div>
//             :
//             <div>
//                 <a href="#" class="btn btn-outline-secondary btn-sm"><strong>{selectCount.select_count}</strong> Selected</a>
//                 <a href={`/print_label/${selectedShipments.join(',')}`} target="_blank" class="btn btn-outline-secondary btn-sm"><strong><i class="fa fa-file-text" aria-hidden="true"></i></strong> Print Label</a>
//                 <a onClick={()=>label_shipments()} class="btn btn-outline-secondary btn-sm"><strong><i class="fa fa-file-text" aria-hidden="true"></i></strong> Download Label</a>
              
//             </div>   
// }  
//           </div>
//         </section>


//         <section class="mb-3">
//           <div class="container">
//           {isLoading? <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"60px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
//       <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
//     </div>:
//             <table class="table table_box">
//                 <thead  class="">
//                     <tr class="brd">
//                     <th style={{width: "5%"}}>
//                         <div class="form-check form-check-inline pt-2">
//                         <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onChange={handleSelectAll}/>
//                         </div>
//                     </th>
//                     <th>Shipment Details</th>
//                     <th>Customer details</th>
//                     <th>Package Details</th>
//                     <th>Shipping Fee</th>
//                     {/* <th>Pickup Address</th> */}
//                     <th>Shipping Carrier</th>
//                     <th style={{width:" 4%"}}>Status</th>
//                     <th style={{width: "4%"}}>Action</th>
//                   </tr>
//                 </thead>
               
//                 <tbody>
                  
//                 {state.shipment_list.map((sub)=>(
//                     <tr class="brd">
//                         <td>
//                             <div class="form-check form-check-inline">
//                                 <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"   onChange={(event) => handleSelectIndividual(event, sub.shipment_id)}
//               checked={selectedShipments.includes(sub.shipment_id)} />
//                             </div>
//                         </td>
//                         <td>
//                             <h2>
//                               {sub.carrier_tracking_number!=undefined && sub.carrier_tracking_number!=""?sub.carrier_tracking_number:""}
//                             </h2>
//                             <small>
//                             <a href={`/customer/shipment_detail/${sub.shipment_id}`} style={{textDecoration:"none"}} >{sub.order_number}</a><br/>
//                             </small>
                          
//                             <small><Moment format="MMM DD YYYY">
//                         {new Date(sub.created*1000)}
//             </Moment> - <Moment format="hh:mm:ss a">
//                         {new Date(sub.created*1000)}
//             </Moment></small>
//                             <h6>
                               
//                             </h6>
//                         </td>
//                         {sub.receiver.map((item)=>(
//                         <td>
//                             <h4>{item.name}</h4>
//                             <small>{item.email}</small>
//                             <h6>{item.mobile}</h6>
//                         </td>
//                         ))}
//                         <td>
//                             <h4>Dead wt. : {sub.total_weight} kg</h4>
//                             <small>{sub.height} x {sub.width} x {sub.length} (cm)</small>
//                             <h6>Volumetric wt.:  {sub.volumetric_weight} Kg</h6>
//                         </td>
//                         <td>
//                             <h4>₹ {sub.total_amount}</h4>
//                             <small>{sub.payment_mode=="cod" || sub.payment_mode=="cash"?<span class="badge rounded-pill bg-info fw-400">cod</span>:<span class="badge rounded-pill bg-warning fw-400">{sub.payment_mode}</span>}</small>
                          
//                         </td>
                   
//                         <td>
//                             <h6 class="text-bold"><strong>{sub.carrier_name}</strong></h6>
                          
//                             <small>{sub.product_type_name}</small>
//                         </td>
//                         <td>
                          
//                             <h2>{sub.status==0?<span class="badge rounded-pill bg-warning fw-400">Pending</span>:sub.status==10?<span class="badge rounded-pill bg-danger fw-400">RTO</span>:sub.status==1?<span class="badge rounded-pill bg-success fw-400">Pick Up Awaited</span>:sub.status==2?<span class="badge rounded-pill bg-warning fw-400">Picked Up</span>:sub.status==3?<span class="badge rounded-pill bg-danger fw-400">In Transit</span>:sub.status==4?<span class="badge rounded-pill bg-primary fw-400">Out For Delivery</span>:sub.status==5?<span class="badge rounded-pill bg-success fw-400">Delivered</span>:sub.status==6?<span class="badge rounded-pill bg-dark fw-400">Cancelled</span>:sub.status==7?<span class="badge rounded-pill bg-secondary fw-400">Failed</span>:sub.status==8?<span class="badge rounded-pill bg-warning fw-400">NDR</span>:sub.status==9?<><span class="badge rounded-pill bg-danger fw-400">Pickup Scheduled</span><br/><br/>
//                             {sub.pickup_date!=undefined && sub.pickup_date!=""?  <small>on {sub.pickup_date!=undefined?sub.pickup_date:""} at {sub.pickup_time!=undefined?sub.pickup_time:""}</small>:""} </>:<span class="badge rounded-pill bg-danger fw-400">Not Available</span>}</h2>
                          
//                         </td>
//                         <td>
//                             <div class="d-flex ">
//                                 <div class="">
                                    
//                                 </div>
//                                 <div class="ps-2 ">
                             
//                                     <div class="dropdown dd_cust">
//                                         <span type=""  data-bs-toggle="dropdown">
//                                         <i class="material-icons-outlined">more_vert</i>
//                                         </span>
//                                         <ul class="dropdown-menu fs-12">
                                      
//                                         <hr/>
//                                         <li><a class="dropdown-item" href={"/tracking/"+ sub.shipment_id}>Search Shipment</a></li>
//                                         <li></li>
                                        
//                                         <li>{sub.label_status==0?
//                        <a class="dropdown-item" href={"/print_label/"+sub.shipment_id}  target="_blank">Print Label</a>:sub.status==7?"":sub.shipping_label!=undefined && sub.shipping_label!=""? <a href={sub.shipping_label} target="_blank" ></a>:<a onClick={()=>{shipping_label(sub)}}  ></a>}</li>
                                       
//                                         {(sub.status==0 || sub.status==1 || sub.status==9)? <li><a class="dropdown-item text-danger" onClick={()=>cancelModal(sub)}>Cancel Shipment</a></li>
//                                        :""}
                                      

//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </td>
//                     </tr>
//                     ))}
                  
//                 </tbody>
//             </table>    
// } 
//           </div>
//         </section>
//         <TablePagination
//                 component="div"
//                 rowsPerPageOptions={[20,25,30,35,40,45,50]}
//                 count={otherStates.total_count}
//                 page={otherStates.page}
//                 onPageChange={handlePageChange}
//                 rowsPerPage={otherStates.rowsPerPage}
//                 onRowsPerPageChange={handleChangeRowsPerPage}
               
//                         />
        
//     </section>
   

   
//     </div>
<div>
<Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>
             <Modal show={modalState.show} onHide={() => setModalState({ show: false })}>
 <Modal.Header closeButton>
                     <Modal.Title>Do you really want to Cancel ?</Modal.Title>
                 </Modal.Header>
                 <Modal.Body >
                 <div className="row">
                       <div className="col-2 mb-3 mt-5" style={{marginLeft:"25px"}}>
                       <i class="fa fa-envelope " style={{fontSize:"20px",marginTop:"10px"}} aria-hidden="true"></i>
                       </div>
                         <div className="col-md-10 mb-3 mt-5 " style={{marginLeft:"-50px"}}>
                               <input className="form-control" id="first_name" type="text" placeholder="Enter Reason" name="cancel_reason" onChange={(e) => inputHandleChange(e)} required   style={{ background: "transparent", border: "none", borderBottom: "1px solid #000000",  outline:"none", boxShadow:"none"}}/>
                          </div>
                         
                        
                        <div>
                       
                        {cancelshipment==false?
                     <button className="btn mt-3" style={{color: "#fff", backgroundColor: "#D65142", display:"inline-block",textAlign:"center",verticalAlign:"middle",position:"relative",boxSizing:"border-box",marginLeft:"190px",padding: "0.84rem 2.14rem",
     fontSize: "18px",fontWeight:"bold"}} onClick={() =>
       cancelShipment()
                         }>Yes</button>
                         :""}
                     </div>
                     </div>
                 </Modal.Body>
                 <Modal.Footer>
               
                   <Button variant="secondary" onClick={() => setModalState({ show: false })}>
                         Close
                     </Button>
                                               </Modal.Footer>
            </Modal>

                         <Modal show={modalpickupState.show} onHide={() => setModalpickupState({ show: false })} size='lg'>
 <Modal.Header closeButton>
                     <Modal.Title> <h4 class="modal-title">Schedule Your Pickup</h4></Modal.Title>
                 </Modal.Header>
                 <Modal.Body >
              
       <div class="modal-body">
         <div class="row booking_info_bx">
          
             <div class="col-12 col-md-12 mb-3">
               <div class="card bg-light border-0 shadow-sm">
                 <div class="card-body">
                   <div class="d-flex">
                       <div class="p-2 text-primary">
                         <i class="material-icons-outlined">local_shipping</i>
                       </div>
                       <div class="ps-4 ">
                         <h6>Pickup Address</h6>
                         <select class="form-select" name="warehouse_name" onChange={(e)=>inputHandleChangemodal(e)}>
                           <option>Select</option>
                           {addressState.map((sub)=>{
                             return(
                               <option value={sub.address_id}>{sub.warehouse_id}</option>
                            )
                        
                             })}
                        
                         </select>
                       </div>
                   </div>
                 </div>
               </div>
             </div>
             <div class="col-12 col-md-12 mb-3">
               <div class="card bg-light border-0 shadow-sm">
                 <div class="card-body">
                   <div class="d-flex">
                       <div class="p-2 text-primary">
                         <i class="material-icons-outlined">today</i>
                       </div>
                       <div class="ps-4 shcelu_pil">
                         <h6>Schedule Your Pickup</h6>
                         <small>Please select a suitable date and time for the pickup.</small>
                         <div class="mt-3">
                         {nextDates.map((date, index) => (
                           <>
                            <a   class="me-2 btn-time mb-2 text-decoration-none" key={index} onClick={() => handleDateSelect(date)}>{(date)}
                            <select name="time" onChange={(e)=>selectTime(e)}>
                            <option value="9:00">9:00</option>
                            <option value="10:00">10:00</option>
                             <option value="11:00">11:00</option>
                             <option value="12:00">12:00</option>
                             <option value="13:00">13:00</option>
                             <option value="14:00">14:00</option>
                             <option value="15:00">15:00</option>
                             <option value="16:00">16:00</option>
                             <option value="17:00">17:00</option>
                             <option value="18:00">18:00</option>
                           </select>
                           </a>
                         
                         
             </>
           ))}
            
                        
                     
                    
                         {selectedDateTime && <p>Selected Date: {(selectedDateTime)}</p>}      
                       
                         </div>
                       </div>
                   </div>
                 </div>
               </div>
             </div>
         </div>
       </div>
       <div class="modal-footer text-start">
       {loaderpickup==true? <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />:  <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={()=>pickup_request()}>Schedule Pickup</button>}
         <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal" onClick={()=>setModalpickupState({show:false})}>I'll Do this later</button>
       </div>
    
  
                 </Modal.Body>
               
             </Modal>
            <div class="row mb-2">
                <div class="col-12 col-md">
                    <div class="d-flex">
                        <div class="back_btn_wrap">
                            <a href="" class="back_btn">
                           <CornerUpLeft/>
                            </a>
                        </div>
                        <div class="">
                            <h2>RTO Shipments</h2>
                        </div>
                    </div>
                </div>
                {/* <div class="col-12 col-md-5 text-end btns">
                    <a class="btn btn-secondary-o btn-sm" onClick={()=>openPickupModal()}><Truck/> Schedule Pickup</a>
                </div> */}
            </div>
            
            
            <div class="row">
                <div class="col-12 col-md">
                    <div class="row g-1">
                        <div class="col-12 col-md-4">
                            <div class="input-group input-group-sm mb-3">
                                {/* <select class="form-select">
                                    <option>AWB</option>
                                    <option>Order ID</option>
                                    <option>Phone Number</option>
                                </select> */}
                               
                                <input type="text" class="form-control w-50" name="search_shipment" placeholder="Enter Tracking Number" onChange={(e)=>handlesearchShipment(e)} />
                                <button class="btn btn-outline-primary" onClick={(e)=>shipment_list(null,0,true)}>Search</button>
                            </div>
                        </div>
                        {/* <div class="col-12 col-md-2">
                            <input type="date" class="form-control form-control-sm" id="text" placeholder="Enter details name=" />
                        </div> */}
                        {/* <div class="col-12 col-md-2 filter_order">
                            <div class="dropdown">
                            <button type="button" class="btn btn-theme btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                Pickup Location
                            </button>
                            <ul class="dropdown-menu" >
                                <li>
                                    <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                    <label class="form-check-label" for="inlineCheckbox1">Mumbai 2993</label>
                                    </div>
                                </li>
                                <li>
                                    <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                    <label class="form-check-label" for="inlineCheckbox1">Delhi 29383</label>
                                    </div>
                                </li>
                            </ul>
                            </div>
                        </div> */}
                        {/* <div class="col-12 col-md-2 filter_order">
                            <div class="dropdown">
                                <button type="button" class="btn btn-theme btn-sm dropdown-toggle" data-bs-toggle="dropdown">
                                    Payment Mode
                                </button>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                        <label class="form-check-label" for="inlineCheckbox1">Cash On Delivery</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                        <label class="form-check-label" for="inlineCheckbox1">Prepaid</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        {/* <div class="col-12 col-md-2 filter_order">
                            <div class="dropdown">
                                <button type="button" class="btn btn-theme btn-sm dropdown-toggle" data-bs-toggle="dropdown">
                                    Status
                                </button>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                        <label class="form-check-label" for="inlineCheckbox1">Delivered</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                        <label class="form-check-label" for="inlineCheckbox1">Booked</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            {selectCount.select_count==0?
           ""
            : <div class="row mb-2" >
            <div class="col-12 col-md btns">
                <a href="#" class="btn btn-text btn-sm fw-300"><strong>{selectCount.select_count}</strong></a>
                <a onClick={()=>label_shipments()}  class="btn btn-secondary-o btn-sm"><FileDown/> Download Label </a>
                <a href={`/print_label/${selectedShipments.join(',')}`} target="_blank" class="btn btn-secondary-o btn-sm"><BarcodeIcon/> Print Label</a>
                {/* <a href="#" class="btn btn-secondary-o btn-sm"><i data-lucide="file"></i> Print Invoice</a> */}
                {/* <a href="#" class="btn btn-secondary-o btn-sm"><i data-lucide="ban"></i> Cancel</a> */}
            </div>
        </div>} 

            <div class="content-wrapper-data">
                <div class="card border-0">
                  <div class="card-body">
                        <div class="table-data">
                        {isLoading? <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"60px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
      <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
    </div>:
                            <table class="table table-hover ">
                                <thead>
                                <tr class="table-light">
                                    <th>
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onChange={handleSelectAll} />
                                    </th>
                                    <th>Shipment Details</th>
                                    <th>Customer Details</th>
                                    <th>Value</th>
                                    <th>Package Details</th>
                                    <th>Pickup Address</th>
                                    <th>Shipping Fee</th>
                                    <th>Shipping Carrier</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {state.shipment_list.map((sub)=>(
                                    <tr class="ship-now-container">
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                        </td>
                                        <td>
                                            <h3><a href=""><strong>AWB #{sub.carrier_tracking_number?sub.carrier_tracking_number:""}</strong></a></h3>
                                            <h4> #{sub.order_number}</h4>
                                            <h6><Moment format="MMM DD YYYY">
                         {new Date(sub.created*1000)}
             </Moment> - <Moment format="hh:mm:ss a">
                         {new Date(sub.created*1000)}
             </Moment></h6>
                                        </td>
                                        {sub.receiver.map((item)=>(
                        <td>
                        <h4>{item.name}</h4>
                        <h6>{item.city} - {item.pincode}</h6>
                        <h6>{item.mobile}</h6>
                    </td>
                        ))}
                                       
                                        <td>
                                        {/* <h4>₹ {sub.total_amount}</h4> */}
                                            <small><span class="badge rounded-pill bg-warning fw-400">{sub.payment_mode}</span></small>
                                        </td>
                                        <td>
                             <h4>Dead wt. : {sub.total_weight} kg</h4>
                             <small>{sub.height} x {sub.width} x {sub.length} (cm)</small>
                             <h6>Volumetric wt.:  {sub.volumetric_weight} Kg</h6>
                         </td>
                                       
                                        <td>
                                            <h6><a href="#" class="text-dark" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover" data-bs-content="St.293, NY, USA - 300393">{sub.warehouse_id?sub.warehouse_id:sub.warehouse_name?sub.warehouse_name:""}</a></h6>
                                        </td>
                                        <td>
                                        <h4><strong>₹ {sub.total_amount}</strong></h4>
                                        </td>
                                        <td>
                                        <h4><strong>{sub.carrier_name}</strong></h4>
                                        <small>{sub.product_type_name?sub.product_type_name:""}</small>
                                        </td>
                                        
                                        <td>
                                            {/* <h5><span class="badge rounded-pill bg-success fw-400">Ready to Ship</span></h5>
                                            <h5><span class="badge rounded-pill bg-info fw-400">Pickup Sheduled</span></h5>
                                            <h5><span class="badge rounded-pill bg-warning fw-400">Pickedup</span></h5>
                                            <h5><span class="badge rounded-pill bg-danger fw-400">In Transit</span></h5>
                                            <h5><span class="badge rounded-pill bg-primary fw-400">Out For Delivery</span></h5>
                                            <h5><span class="badge rounded-pill bg-success fw-400">Delivered</span></h5>
                                            <h5><span class="badge rounded-pill bg-dark fw-400">Cancelled</span></h5>
                                            <h5><span class="badge rounded-pill bg-secondary fw-400">Failed</span></h5>
                                            <h5><span class="badge rounded-pill bg-info fw-400">RTO</span></h5> */}
                                            {sub.status==0?<span class="badge rounded-pill bg-warning fw-400">Pending</span>:sub.status==10?<span class="badge rounded-pill bg-danger fw-400">RTO</span>:sub.status==1?<span class="badge rounded-pill bg-success fw-400">Pick Up Awaited</span>:sub.status==2?<span class="badge rounded-pill bg-warning fw-400">Picked Up</span>:sub.status==3?<span class="badge rounded-pill bg-danger fw-400">In Transit</span>:sub.status==4?<span class="badge rounded-pill bg-primary fw-400">Out For Delivery</span>:sub.status==5?<span class="badge rounded-pill bg-success fw-400">Delivered</span>:sub.status==6?<span class="badge rounded-pill bg-dark fw-400">Cancelled</span>:sub.status==7?<span class="badge rounded-pill bg-secondary fw-400">Failed</span>:sub.status==8?<span class="badge rounded-pill bg-warning fw-400">NDR</span>:sub.status==9?<><span class="badge rounded-pill bg-danger fw-400">Pickup Scheduled</span><br/><br/>
                            {sub.pickup_date!=undefined && sub.pickup_date!=""?  <small>on {sub.pickup_date!=undefined?sub.pickup_date:""} at {sub.pickup_time!=undefined?sub.pickup_time:""}</small>:""} </>:<span class="badge rounded-pill bg-danger fw-400">Not Available</span>}

                                            <div class="custom-buttons button-container">
                                                <div class="d-flex justify-content-between">
                                                    <div class="p-2 ship_now_2">
                                                        <div class="mb-1">
                                                        {sub.label_status==0?
                       <a class="btn btn-secondary btn-sm" href={"/print_label/"+sub.shipment_id}  target="_blank">Print Label</a>:sub.status==7?"":sub.shipping_label!=undefined && sub.shipping_label!=""? <a href={sub.shipping_label} target="_blank" ></a>:<a onClick={()=>{shipping_label(sub)}} title="Print Label" ></a>}
                                                            <a href="#" class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" title="Print Label">
                                                                <i class="fa fa-barcode" aria-hidden="true"></i>
                                                            </a>
                                                        </div>
                                                       

{/* {(sub.status==0 || sub.status==1 || sub.status==9)?
                                                        <div class="mb-1">
                                                            <a class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" onClick={()=>cancelModal(sub)}title="Cancel Shipment">
                                                                <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                            </a>
                                                        </div> 
                                                        :""} */}
                                                        <div>
                                                            <a  href={"/tracking/"+ sub.shipment_id} class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" title="Shipment Tracking">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            }
                        </div>
                    </div>
                </div>
            </div>


            
            
                <section class="shipment_info_offcanvas">
                    
                    <div class="offcanvas offcanvas-end " id="shipment_details">
                        <div class="offcanvas-header">
                            <div class="offcanvas-title">
                                <div class="row mb-2">
                                    <div class="col-12 col-md">
                                     <h2><small>Shipment #</small>298239842348</h2>
                                    </div>
                                    <div class="col-12 col-md-2 text-end">
                                        <span class="badge rounded-pill bg-success status_badge"><i data-lucide="package-check"></i> Delivered</span>
                                    </div>
                                    <div class="col-12 col-md-4 text-end btns">
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Download Label"><i data-lucide="file-down"></i></a>
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Print Label"><i data-lucide="barcode"></i></a>
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Print Invoice"><i data-lucide="file"></i></a>
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Shipment Tracking"><i data-lucide="package-search"></i></a>
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Cancel"><i data-lucide="ban"></i></a>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div class="">
                                <div class="row mt-3 order_box_cont">
                                    <div class="col-12 col-md-8 ">
                                        <div class="card border-0 mb-3">
                                            <div class="card-body">
                                                <div class="d-flex ">
                                                    <div class="border-end pe-3">
                                                    <i data-lucide="shopping-bag"></i>
                                                    </div>
                                                    <div class="ps-3 ">
                                                        <h6>Order Details</h6>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Order created on channel</small>
                                                        <p>11 Jul 2023 12:00 AM</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Order created on system</small>
                                                        <p>11 Jul 2023 03:41 PM</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Channel</small>
                                                        <p>IndoChina Store - Woocommerce</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Shipment value</small>
                                                        <p>₹ 2000 <span class="badge rounded-pill bg-warning">Prepaid</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card border-0 mb-3">
                                        <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                <i data-lucide="package"></i>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Shipment Details</h6>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row">
                                                <div class="col-12 col-md">
                                                    <h6>Ship From:</h6>
                                                        <small class="text-muted">Sender Name:</small>
                                                        <p>Rahul Gandi</p>
                                                        <small class="text-muted">Sender Address:</small>
                                                        <p>Lodhiya Street, New Delhi, Delhi 110001</p>
                                                        <small class="text-muted">Sender Email:</small>
                                                        <p>rahulgandi@gmail.com</p>
                                                        <small class="text-muted">Sender Mobile:</small>
                                                        <p>123456789</p>
                                                </div>
                                                <div class="col-12 col-md">
                                                    <h6>Ship To:</h6>
                                                        <small class="text-muted">Receiver Name:</small>
                                                        <p>Rahul Gandi</p>
                                                        <small class="text-muted">Receiver Address:</small>
                                                        <p>Lodhiya Street, New Delhi, Delhi 110001</p>
                                                        <small class="text-muted">Receiver Email:</small>
                                                        <p>rahulgandi@gmail.com</p>
                                                        <small class="text-muted">Receiver Mobile:</small>
                                                        <p>123456789</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>

                                        <div class="card border-0 mb-3">
                                            <div class="card-body">
                                                <div class="d-flex ">
                                                    <div class="border-end pe-3">
                                                    <i data-lucide="truck"></i>
                                                    </div>
                                                    <div class="ps-3 ">
                                                        <h6>Shipping Details</h6>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <div class="col-12 col-md-4 mb-2">
                                                        <small class="text-muted">Shipping Courier</small>
                                                        <p>Xpressbees Surface</p>
                                                    </div>
                                                    <div class="col-12 col-md-4 mb-2">
                                                        <small class="text-muted">AWB No.</small>
                                                        <p><a href="">141123828201017</a></p>
                                                    </div>
                                                    <div class="col-12 col-md-4 mb-2">
                                                        <small class="text-muted">Mode</small>
                                                        <p>Surface</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Courier Rule Applied</small>
                                                        <p>N/A</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card border-0  mb-3">
                                            <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                <i data-lucide="dollar-sign"></i>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Payment Details</h6>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row">
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Total Paid Amount</small>
                                                    <p>₹ 2983.00</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Payment Mode</small>
                                                    <p>Wallet</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Transaction ID</small>
                                                    <p><a href="">3456789876</a></p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div class="card border-0  mb-3">
                                            <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                <i data-lucide="package-open"></i>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Package Details</h6>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row">
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Applicable Weight (in Kg)</small>
                                                    <p>1KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Dead Weight (in Kg)</small>
                                                    <p>1KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Volumetric Weight (in kg)</small>
                                                    <p>1KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Dimensions (in cm)</small>
                                                    <p>1.000 x 1.000 x 1.000</p>
                                                </div>
                                                <div class="col-12 col-md-6 mb-2">
                                                    <small class="text-muted">Number of Boxes</small>
                                                    <p>1</p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div class="card border-0 mb-3">
                                            <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                <i data-lucide="shopping-basket"></i>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Product Details</h6>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="table-responsive ">
                                                <table class="table table-light">
                                                    <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Category</th>
                                                        <th>HSN</th>
                                                        <th>SKU</th>
                                                        <th>Qty</th>
                                                        <th>Unit price</th>
                                                        <th>Discount</th>
                                                        <th>Tax</th>
                                                        <th>Total</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Samsung mobile Transparent Covers - Samsung-A50</td>
                                                        <td>1689070274414</td>
                                                        <td>samsung-A50</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>200.00</td>
                                                        <td>100.00</td>
                                                        <td>10.00</td>
                                                        <td>100.00</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            </div>
                                        </div>

                                        
                                        
                                        
                                    </div>
                                    <div class="col-12 col-md-4">
                                    <div class="card border-0 shadow-sm tracking_xb">
                                        <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                <i data-lucide="package-search"></i>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Tracking Info</h6>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="progress-container  position-relative">
                                                <div class="step-container">
                                                <div class="step active">
                                                    <i class="material-icons-outlined">radio_button_checked</i>
                                                </div>
                                                <div class="progress"></div>
                                                <div class="step-info">
                                                    <h6>In Transit</h6>
                                                    <p>Shipment Location: City A</p>
                                                    <p>Time: 10:00 AM</p>
                                                </div>
                                                </div>
                                                <div class="step-container">
                                                <div class="step ">
                                                    <i class="material-icons-outlined">radio_button_unchecked</i>
                                                </div>
                                                <div class="progress"></div>
                                                <div class="step-info">
                                                    <h6>In Transit</h6>
                                                    <p>Shipment Location: City B</p>
                                                    <p>Time: 11:30 AM</p>
                                                </div>
                                                </div>
                                                <div class="step-container">
                                                <div class="step">
                                                    <i class="material-icons-outlined">radio_button_unchecked</i>
                                                </div>
                                                <div class="progress"></div>
                                                <div class="step-info">
                                                    <h6>In Transit</h6>
                                                    <p>Shipment Location: City C</p>
                                                    <p>Time: 1:00 PM</p>
                                                </div>
                                                </div>
                                                <div class="step-container">
                                                <div class="step">
                                                    <i class="material-icons-outlined">radio_button_unchecked</i>
                                                </div>
                                                <div class="progress"></div>
                                                <div class="step-info">
                                                    <h6>In Transit</h6>
                                                    <p>Shipment Location: City D</p>
                                                    <p>Time: 3:15 PM</p>
                                                </div>
                                                </div>
                                                <div class="step-container">
                                                <div class="step">
                                                    <i class="material-icons-outlined">radio_button_unchecked</i>
                                                </div>
                                                <div class="progress"></div>
                                                <div class="step-info">
                                                    <h6>In Transit</h6>
                                                    <p>Shipment Location: City E</p>
                                                    <p>Time: 5:30 PM</p>
                                                </div>
                                                </div>
                                            </div>
                                            No Data Available
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <TablePagination
                component="div"
                 rowsPerPageOptions={[20,25,30,35,40,45,50]}
                 count={otherStates.total_count}
                 page={otherStates.page}
                 onPageChange={handlePageChange}
                 rowsPerPage={otherStates.rowsPerPage}
                 onRowsPerPageChange={handleChangeRowsPerPage}
               
                         />
       
        {/* <!-- Main content area END --> */}
  
       </div>
  </div>

  )
}

export default Rto_shipments
